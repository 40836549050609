import axios from "axios";
import {APP_TOKEN, BASE_URL, ENDPOINTS} from "../config";
import {
  UsersNewResponse,
  PartiesNewResponse,
  VehiclesNewResponse,
  TripsNewResponse,
  StoriesNewRequest,
  StoriesNewOwnTourResponse, StoriesStartRequest, StoriesLocationCollectOneRequest
} from "./types";

const apiClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Rhythm-App': APP_TOKEN
  },
});

async function usersNew() {
  const url = ENDPOINTS.usersNew
  try {
    return apiClient.post<UsersNewResponse>(url);
  } catch (err) {
    console.log(err);
  }
}

async function partiesNew(bearerToken: string) {
  const url = ENDPOINTS.partiesNew
  apiClient.defaults.headers.post['Authorization'] = `Bearer ${bearerToken}`;
  try {
    // postのデータ部分は固定
    return apiClient.post<PartiesNewResponse>(url, {"composition_type" :"family", "members_count": 4, "name": "Fantastic 4"});
  } catch (err) {
    console.log(err);
  }
}

async function vehiclesNew(bearerToken: string) {
  const url = ENDPOINTS.vehiclesNew
  apiClient.defaults.headers.post['Authorization'] = `Bearer ${bearerToken}`;
  try {
    // postのデータ部分は固定
    return apiClient.post<VehiclesNewResponse>(url, {"type": "unknown", "vendor" : "unknown"});
  } catch (err) {
    console.log(err);
  }
}

async function tripsNew(bearerToken: string) {
  const url = ENDPOINTS.tripsNew
  apiClient.defaults.headers.post['Authorization'] = `Bearer ${bearerToken}`;
  try {
    // postのデータ部分は固定
    return apiClient.post<TripsNewResponse>(url, {"name": "Special_PREMIUM", "reference_number" : "Special_PREMIUM"});
  } catch (err) {
    console.log(err);
  }
}

async function storiesNewOwnTour(bearerToken: string, request: StoriesNewRequest) {
  const url = ENDPOINTS.storiesNewOwnTour
  apiClient.defaults.headers.post['Authorization'] = `Bearer ${bearerToken}`;
  try {
    // postのデータ部分は固定
    return apiClient.post<StoriesNewOwnTourResponse>(url, request);
  } catch (err) {
    console.log(err);
  }
}

async function storiesStart(bearerToken: string, story_id: string, request: StoriesStartRequest) {
  const url = ENDPOINTS.storiesStart.replace('{story_id}', story_id);
  apiClient.defaults.headers.post['Authorization'] = `Bearer ${bearerToken}`;
  try {
    return apiClient.post(url, request);
  } catch (err) {
    console.log(err);
  }
}

async function storiesLocationCollectOne(bearerToken: string, story_id: string, request: StoriesLocationCollectOneRequest) {
  const url = ENDPOINTS.storiesNewLocationsCollectOne.replace('{story_id}', story_id);
  apiClient.defaults.headers.post['Authorization'] = `Bearer ${bearerToken}`;
  try {
    return apiClient.post(url, request);
  } catch (err) {
    console.log(err);
  }
}

async function storiesCommands(bearerToken: string, story_id: string) {
  const url = ENDPOINTS.storiesCommands.replace('{story_id}', story_id);
  apiClient.defaults.headers.get['Authorization'] = `Bearer ${bearerToken}`;
  try {
    return apiClient.get(url);
  } catch (err) {
    console.log(err);
  }
}

async function storiesRecommendations(bearerToken: string, story_id: string, recommendation_id: string) {
  const url = ENDPOINTS.storiesRecommendations.replace('{story_id}', story_id).replace('{recommendation_id}', recommendation_id);
  apiClient.defaults.headers.get['Authorization'] = `Bearer ${bearerToken}`;
  try {
    return apiClient.get(url);
  } catch (err) {
    console.log(err);
  }
}

export const Api = {
  usersNew,
  partiesNew,
  vehiclesNew,
  tripsNew,
  storiesNewOwnTour,
  storiesStart,
  storiesLocationCollectOne,
  storiesCommands,
  storiesRecommendations,
}
